import { createApp } from 'vue';
import App from './App.vue';
import router from "./router";

const app = createApp(App);

// Global Variable for Base API
app.config.globalProperties.$baseApi = process.env.VUE_APP_BASE_API;

app.use(router);
app.mount("#app");
