import { createRouter, createWebHistory } from "vue-router";

// Home
const NotFound = () => import(/* webpackChunkName: "not-found" */ '../views/NotFoundView.vue');
const HomeWrapperView = () => import(/* webpackChunkName: "home-wrapper-view" */ '@/views/HomeWrapperView.vue');
const HomePageView = () => import(/* webpackChunkName: "home-page-view" */ '@/views/HomePageView.vue');
const TalentCategoriesView = () => import(/* webpackChunkName: "talent-categories-view" */ '@/views/talent/TalentCategoriesView.vue');
const TalentCategoryView = () => import(/* webpackChunkName: "talent-category-view" */ '@/views/talent/TalentCategoryView.vue');
const TalentSingleView = () => import(/* webpackChunkName: "talent-single-view" */ '@/views/talent/TalentSingleView.vue');
const TeamView = () => import(/* webpackChunkName: "team-view" */ '@/views/TeamView.vue');
const ContactView = () => import(/* webpackChunkName: "contact-view" */ '@/views/ContactView.vue');
const TermsOfBusinessView = () => import(/* webpackChunkName: "terms-of-business-view" */ '@/views/TermsOfBusinessView.vue');


const router = createRouter({
  history: createWebHistory(),
  base: process.env.BASE_URL,
  routes: [
    {
      // for urls that don't exist
      name: "NotFound",
      path: "/:pathMatch(.*)*",
      component: NotFound,
      meta: {
        requiresAuth: false
      }
    },
    {
      path: "/",
      name: "HomePage",
      component: HomePageView,
      meta: {
        title: 'Home Page',
      },
    },

    {
      path: "/talent",
      name: "TalentCategories",
      component: TalentCategoriesView,
      meta: {
        title: 'Talents',
      },
    },

    {
      path: "/talent/:category_slug",
      name: "TalentCategory",
      component: TalentCategoryView,
    },

    {
      path: "/talent/:category_slug/:name_slug",
      name: "TalentSingle",
      component: TalentSingleView,
    },

    {
      path: "/team",
      name: "TeamView",
      component: TeamView,
    },

    {
      path: "/contact",
      name: "ContactView",
      component: ContactView,
      meta: {
        title: 'Contact Us',
      },
    },

    {
      path: "/terms",
      name: "TermsOfBusinessView",
      component: TermsOfBusinessView,
      meta: {
        title: 'Contact Us',
      },
    },

  ],
});

router.beforeEach((to, from, next) => {
  if (to.meta.disableBackNavigation) {
    window.history.pushState(null, null, window.location.href)
    window.onpopstate = function () {
      window.history.go(1)
    }
  }
  next()
})

export default router;
